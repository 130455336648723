import styled from "styled-components";
import Image from "gatsby-image";
const ServicesContainer = styled.div`
  padding-top: 0;
  ${({ theme }) => theme.mq.md`
    padding-bottom: 0px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding-bottom: 0px;
  `}
`;
const Flex = styled.div`
  display: flex;
  width: 100vw;
  padding: 0 5.6vw 0 4vw;
  flex-direction: ${props => (props.direction === "reverse" ? "column-reverse" : "column")};
  position: relative;
  align-items: flex-start;
  ${({ theme }) => theme.mq.sm`
    // flex-direction: column-reverse;
  `}
  ${({ theme }) => theme.mq.md`
    // flex-direction: column-reverse;
  `}
  ${({ theme }) => theme.mq.lg`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
  ${({ theme }) => theme.mq.xl`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;
const Title = styled.div`
  color: #210f60;
  font-weight: 600;
  font-size: 6.67vw;
  text-align: left;
  line-height: 10vw;
  padding-left: 6.67vw;
  padding-bottom: 0.67vw;

  ${({ theme }) => theme.mq.sm`
    text-align: left;
    font-size: 6.67vw;
  `}

  ${({ theme }) => theme.mq.md`
    text-align: left;
    font-size: 6.67vw;
  `}
  ${({ theme }) => theme.mq.lg`
    text-align: left;
    font-size: 3.1vw;
    line-height: 4.17vw;
    padding-left: 5.69vw;
    padding-bottom: 2.6vw;
    margin-left: ${props => (props.ml ? props.ml : 0)}
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: left;
    font-size: 3.1vw;
    line-height: 4.17vw;
    padding-left: 5.69vw;
    padding-bottom: 2.6vw;
  `}
`;

const SubTitle = styled.div`
  color: ${props => (props.color ? props.color : "#ffffff")};
  font-size: 5.56vw;
  font-weight: 700;
  text-align: left;
  margin-top: 2.78vw;
  letter-spacing: 0.1vw;
  margin-bottom: 2.78vw;
  line-height:  ${props => (props.lh ? props.lh : "8.3vw")};
  padding-left: 0;
  ${({ theme }) => theme.mq.sm`
    text-align: left;
    margin-top: 2.78vw;
  `}
  ${({ theme }) => theme.mq.md`
    text-align: left;
    margin-top: 2.78vw;
  `}
  ${({ theme }) => theme.mq.lg`
    text-align: left;
    font-size: 2.78vw;
    line-height:  ${props => (props.lh ? props.lh : "3.47vw")};
    margin-top: 0;
    margin-left: ${props => (props.ml ? props.ml : 0)};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    width: ${props => (props.width ? props.width : "")};
    margin-bottom: 0;
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: left;
    font-size: 2.78vw;
    line-height:  ${props => (props.lh ? props.lh : "3.4vw")};
    margin-top: 0;
    margin-bottom: 0;
    width: ${props => (props.width ? props.width : "")};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    margin-left: ${props => (props.ml ? props.ml : 0)};
  `}
`;

const NewContainerBox = styled.div`
  width: 100vw;
  background: #1dc99b;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
`;

const Content = styled.div`
  font-size: 3.88vw;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.08vw;
  width: 100%;
  line-height: 5.83vw;
  padding-bottom: 8.33vw;
  padding-left: 0;
  color: ${props => (props.color == "#210F60" ? "#1DCF9F" : "#ffffff")};

  ${({ theme }) => theme.mq.lg`
    text-align: left;
    margin-top: ${props => (props.mt ? props.mt : 0)};
    font-size: 1.56vw;
    line-height: 2.43vw;
    padding-bottom: 0;
    width: ${props => (props.width ? props.width : "33.7vw")};
    padding-left: ${props => (props.pl ? props.pl : "0")};
    color: ${props => (props.color ? props.color : "#ffffff")};
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: left;
    margin-top: ${props => (props.mt ? props.mt : 0)};
    font-size: 1.56vw;
    line-height: 2.43vw;
    padding-bottom: 0;
    width: ${props => (props.width ? props.width : "33.7vw")};
    padding-left: ${props => (props.pl ? props.pl : "0")};
    color: ${props => (props.color ? props.color : "#ffffff")};
  `}
`;

const Img = styled.div`

  width: 100%;
  margin: 6.67vw auto 0 auto;
  border-radius: 1.9vw;
  overflow: hidden;
  z-index: 0;
  margin-left: 0;
  ${({ theme }) => theme.mq.sm`

  `}

  ${({ theme }) => theme.mq.md`

  `}

  ${({ theme }) => theme.mq.lg`
    width: 43.8vw;
    margin: 4.43vw 0;
    border-radius: 1vw;
    overflow: hidden;
    z-index: 0;
    margin-left: 56px;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 43.8vw;
    margin: 4.43vw 0;
    border-radius: 1vw;
    overflow: hidden;
    z-index: 0;
    margin-left: 56px;
  `}
`;
const StyledHeaderBg = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
const CommonContainer = styled.div`
  margin-top: ${props => (props.mt ? props.mt : 0)};
  margin-left: 0;
  ${({ theme }) => theme.mq.lg`
    padding-right: ${props => (props.pr ? props.pr : 0)};
    width: ${props => (props.width ? props.width : "100%")};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    margin-top: 0;
    margin-left: ${props => (props.mf ? props.mf : 0)};
  `}
  ${({ theme }) => theme.mq.xl`
    padding-right: ${props => (props.pr ? props.pr : 0)};
    width: ${props => (props.width ? props.width : "100%")};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    margin-top: 0;
    margin-left: ${props => (props.mf ? props.mf : 0)};
  `}
`;

export { ServicesContainer, Flex, Title, SubTitle, Content, Img, CommonContainer, NewContainerBox, StyledHeaderBg };
