import styled from "styled-components";
import Image from "gatsby-image";
const ServicesContainer = styled.div`
  padding-top: 0;
  padding-bottom: 0px;
  height: 35.65vw;
  ${({ theme }) => theme.mq.md`
    padding-bottom: 0px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding-bottom: 0px;
  `}
`;
const Flex = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction === "reverse" ? "column-reverse" : "column")};
  align-items: center;
  margin-top: 0px;
  position: relative;
  ${({ theme }) => theme.mq.lg`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
  `}
  ${({ theme }) => theme.mq.xl`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
  `}
`;
const Title = styled.div`
  color: #210f60;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  line-height: 48px;
  ${({ theme }) => theme.mq.lg`
    text-align: left;
    margin-left: ${props => (props.ml ? props.ml : 0)}
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: left;
  `}
`;

const SubTitle = styled.div`
  color: ${props => (props.color ? props.color : "#ffffff")};
  font-size: 4.17vw;
  font-weight: 600;
  text-align: center;
  line-height: 5.21vw;
  ${({ theme }) => theme.mq.lg`
    margin-top: 12.6vw;
    text-align: center;
    margin-left: ${props => (props.ml ? props.ml : 0)}
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: center;
  `}
`;

const NewContainerBox = styled.div`
  color: #210f60;
  width: 100vw;
  position: absolute;
  height: 35.6vw;
  overflow: hidden;
  left: calc(0px - (100vw - 1200px + 32px) / 2);
  top: 0;
  background-repeat: repeat;
  background-size: contain;
`;

const Content = styled.div`
  color: ${props => (props.color ? props.color : "#210F60")};
  font-size: ${props => (props.fontSize ? props.fontSize : "24px")};
  font-weight: 400;
  font-weight: ${props => (props.fw ? props.fw : "400")};
  text-align: center;
  width: ${props => (props.width ? props.width : "100%")};
  line-height: ${props => (props.lh ? props.lh : "60px")};
  ${({ theme }) => theme.mq.lg`
    text-align: center;
    margin-top: ${props => (props.mt ? props.mt : 0)}
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: center;
    margin-top: ${props => (props.mt ? props.mt : 0)}
  `}
`;

const SubContent = styled.div`
  color: ${props => (props.color ? props.color : "#210F60")};
  font-size: ${props => (props.fontSize ? props.fontSize : "24px")};
  font-weight: 400;
  margin-top: ${props => (props.mt ? props.mt : "0")};
  font-weight: ${props => (props.fw ? props.fw : "400")};
  text-align: center;
  width: ${props => (props.width ? props.width : "100%")};
  ${({ theme }) => theme.mq.lg`
    text-align: center;
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: center;
  `}
`;

const Img = styled(Image)`
  width: ${props => (props.width ? props.width : "100%")};
  ${({ theme }) => theme.mq.lg`
    width: 500px;
    margin: 0;
  `}
  ${({ theme }) => theme.mq.xl`
    width: ${props => props.width};
    position: relative;
  `}
`;

const CommonContainer = styled.div`
  margin-top: ${props => (props.mt ? props.mt : 0)};
  ${({ theme }) => theme.mq.lg`
    padding-right: ${props => (props.pr ? props.pr : 0)};
    width: ${props => (props.width ? props.width : "100%")};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    margin-top: 0;
  `}
  ${({ theme }) => theme.mq.xl`
    padding-right: ${props => (props.pr ? props.pr : 0)};
    width: ${props => (props.width ? props.width : "100%")};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    margin-top: 0;
  `}
`;

export { ServicesContainer, Flex, Title, SubTitle, Content, SubContent, Img, CommonContainer, NewContainerBox };
