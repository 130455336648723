import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Box, Flex, Text, Heading } from "rebass/styled-components";
import { Container } from "../../../global";

import QRCode from "../../../../images/product/new-qr-code.svg";
import { useLocationCountry } from "../../../../hooks";
import Img from "gatsby-image";
import HeroSection from "../../hero";

const DownloadAppSection = () => {
  const data = useStaticQuery(graphql`
    query {
      botVideoMaskBg: file(sourceInstanceName: { eq: "product" }, name: { eq: "bot-video-mask-bg" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [popVideo, setPopVideo] = useState(null);
  const bannerVideo1 = "https://files.opayweb.com/file/video/timeline1127.mp4";

  const [isMob, setIsMob] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMob(true);
    }
  }, []);

  const playVideo = e => {
    let videoDom = document.getElementById("bt_video_1");
    if (videoDom && videoDom.paused) {
      // videoDom.play();
    }
    e.stopPropagation();
  };

  const stopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoDom = document.getElementById(videoId.split("_mask")[0]);
    if (!videoDom.paused) {
      setTimeout(() => {
        videoDom.pause();
      }, 300);
      document.getElementById(videoId).style.opacity = 1;
    }
    e.stopPropagation();
  };

  const playPopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoIndex = videoId.split("_mask")[0].split("video_")[1];
    let videoUrl = "";
    if (videoIndex == 1) {
      videoUrl = bannerVideo1;
    } else if (videoIndex == 2) {
      videoUrl = bannerVideo1;
    }
    setPopVideo(videoUrl);
    setTimeout(() => {
      document.getElementById("pop_video").play();
    }, 100);
  };

  const closePopVideo = () => {
    setPopVideo(null);
  };

  return (
    <WrapperContainer>
      <Container>
        <FlexWrapper>
          {isMob == false ? (
            <FadePC>
              <video
                preload="none"
                autoplay="true"
                playsinline="playsinline"
                muted="true"
                style={{ height: "100%", width: "100%", left: "0", top: "0", objectFit: "cover" }}
                loop="true"
                class="css-ghlqxi"
                id={"bt_video_1"}
              >
                <source src={bannerVideo1} type="video/mp4" />
              </video>
            </FadePC>
          ) : (
            <FadeMobile style={{ position: "relative" }}>
              <MobileImgMsk objectFit="contain" fluid={data.botVideoMaskBg.childImageSharp.fluid}></MobileImgMsk>
              <video
                preload="metadata"
                controls="true"
                muted="false"
                playsinline="playsinline"
                style={{
                  height: "100%",
                  width: "100%",
                  left: "0",
                  top: "0",
                  objectFit: "cover",
                  zIndex: 2,
                  position: "relative",
                }}
                loop="true"
                class="css-ghlqxi"
              >
                <source src={bannerVideo1} type="video/mp4" />
              </video>
            </FadeMobile>
          )}
        </FlexWrapper>
      </Container>
    </WrapperContainer>
  );
};

export default DownloadAppSection;

const CTA = styled(OutboundLink)`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const MobileImgMsk = styled(Img)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
`;

const StyledSVG = styled.div`
  // background-color: ${({ theme }) => theme.colors.white.regular};
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }

  ${({ theme }) => theme.mq.md`
  width: 100px;
  height: 100px;

  svg {
    width: 100px;
    height: 100px;
  }
  `}

  ${({ theme }) => theme.mq.lg`
  width: 163px;
  height: 154px;

   svg {
    width: 163px;
    height: 154px;
  }
  `}

  ${({ theme }) => theme.mq.xl`
    width: 163px;
  height: 154px;

   svg {
     width: 163px;
     height: 154px;
  }
  `}
`;

const StyledTextWrapper = styled.div`
  word-wrap: break-word;
  padding-bottom: 20px;
  margin-left: 48px;
  ${({ theme }) => theme.mq.md`
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-left: 0;
    width: calc(100% - 675px);
    flex-shrink: 0;
    padding-bottom: 0;
    margin-left: 48px;
  `}

  ${({ theme }) => theme.mq.lg`
     width: calc(100% - 675px);
     padding-bottom: 0;
     margin-left: 48px;
  `}

  ${({ theme }) => theme.mq.xl`
    width: calc(100% - 675px);
    padding-bottom: 0;
    margin-left: 48px;
  `}
`;

const WrapperContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #D4D4D4;
  ${({ theme }) => theme.mq.md`
    // height: 470px;
  `}

  ${({ theme }) => theme.mq.lg`
    // height: 470px;
  `}

  ${({ theme }) => theme.mq.xl`
    // height: 470px;
  `}

`;

const FlexWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  /* padding: 20rem 0; */
  width: 86.67vw;
  margin: 0 auto;
  padding: 9.16vw 0 6.94vw 0;
  ${({ theme }) => theme.mq.md`
    flex-direction: column;
    justify-content: center;
    align-items:center;
  `}

  ${({ theme }) => theme.mq.lg`
    align-items: center;
    flex-direction: row;
    // height: 470px;
    justify-content: flex-start;
    width: 72.69vw;
    padding: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    align-items: center;
    flex-direction: row;
    // height: 470px;
    justify-content: flex-start;
    width: 72.69vw;
    padding: 0;
  `}
`;

const FadeMobile = styled.div`
  display: block;
  ${({ theme }) => theme.mq.sm`
    display: block;
  `}
  ${({ theme }) => theme.mq.md`
    display: block;
  `}
  ${({ theme }) => theme.mq.lg`
    display: none;
  `}
  ${({ theme }) => theme.mq.xl`
    display: none;
  `}
`;
const FadePC = styled.div`
  display: none;
  ${({ theme }) => theme.mq.sm`
    display: none;
  `}
  ${({ theme }) => theme.mq.md`
    display: none;
  `}
  ${({ theme }) => theme.mq.lg`
    display: block;
  `}
  ${({ theme }) => theme.mq.xl`
    display: block;
  `}
`;
