import React, { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Box, Flex, Text, Heading } from "rebass/styled-components";
import { Container } from "../../../global";

import QRCode from "../../../../images/product/new-qr-code.svg";
import { useLocationCountry } from "../../../../hooks";
import Img from "gatsby-image";
import HeroSection from "../../hero";

const DownloadAppSection = () => {
  const [popVideo, setPopVideo] = useState(null);
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          playstoreUrl
          applestoreUrl
        }
      }

      web021Img: file(sourceInstanceName: { eq: "product" }, name: { eq: "web02-1" }) {
        childImageSharp {
          fluid(maxWidth: 741) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      web022Img: file(sourceInstanceName: { eq: "product" }, name: { eq: "web02-2" }) {
        childImageSharp {
          fluid(maxWidth: 741) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      videoPlayIconNew: file(sourceInstanceName: { eq: "product" }, name: { eq: "video-play-icon" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      telIcon: file(sourceInstanceName: { eq: "product" }, name: { eq: "telIcon" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { isPakistan } = useLocationCountry();
  const bannerVideo1 = "https://files.opayweb.com/file/video/01CustomerService-People_4.mp4";
  const bannerVideo2 = "https://files.opayweb.com/file/video/02customerservice01.mp4";

  const playVideo = e => {
    // console.log(e.currentTarget.getAttribute('id').split('_mask')[0])
    let videoId = e.currentTarget.getAttribute("id");
    let videoDom = document.getElementById(videoId.split("_mask")[0]);
    videoDom.currentTime = 8;
    if (videoDom && videoDom.paused) {
      videoDom.play();
      document.getElementById(videoId).style.opacity = 0;
      // e.currentTarget.style.opacity = 0
    }
    e.stopPropagation();
  };

  const stopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoDom = document.getElementById(videoId.split("_mask")[0]);
    if (!videoDom.paused) {
      setTimeout(() => {
        videoDom.pause();
      }, 300);
      document.getElementById(videoId).style.opacity = 1;
    }
    e.stopPropagation();
  };

  const playPopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoIndex = videoId.split("_mask")[0].split("video_")[1];
    let videoUrl = "";
    if (videoIndex == 1) {
      videoUrl = bannerVideo1;
    } else if (videoIndex == 2) {
      videoUrl = bannerVideo2;
    }
    setPopVideo(videoUrl);
    setTimeout(() => {
      document.getElementById("pop_video").play();
    }, 100);
    console.log(videoIndex);
  };

  const playPopVideoMob = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoIndex = videoId.split("_mask_mob")[0].split("video_")[1];
    let videoUrl = "";
    if (videoIndex == 1) {
      videoUrl = bannerVideo1;
    } else if (videoIndex == 2) {
      videoUrl = bannerVideo2;
    }
    setPopVideo(videoUrl);
    console.log(videoIndex);
  };

  const closePopVideo = () => {
    setPopVideo(null);
  };

  return (
    <WrapperContainer>
      <Container style={{ padding: "4.78vw 0", width: "100vw", maxWidth: "100vw" }}>
        <FlexWrapper>
          <StyledTextWrapper>
            <RightItemTitle>Customer Satisfaction, Our Priority</RightItemTitle>
            <RightItemDesc>
              we are here for you to provide 7*24 quick customer service, visit our in-app customer service center or
              physical customer service center or send us message via official social medias or calll us via hotline as
              below
            </RightItemDesc>
            <LeftTels>
              <LeftTelsLeft>
                <BannerImg objectFit="contain" fluid={data.telIcon.childImageSharp.fluid}></BannerImg>
              </LeftTelsLeft>
              <LeftTelsRight>
                <p>0700 8888 328 OR 020 18888 328 (App & Card queries)</p>
                <p>0700 8888 329 OR 020 18888 329 (POS Business queries)</p>
              </LeftTelsRight>
            </LeftTels>
          </StyledTextWrapper>
          <LeftBox>
            <LeftItem>
              <BannerVideoBox>
                <FadeMobile>
                  <SlideVideoImg onClick={playPopVideoMob} id={"cs_video_1_mask_mob"}>
                    <VideoImg
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        marginLeft: "0",
                        marginTop: "0",
                        objectFit: "cover",
                      }}
                      fluid={data.web021Img.childImageSharp.fluid}
                    />
                    <VideoImg objectFit="contain" fluid={data.videoPlayIconNew.childImageSharp.fluid} />
                  </SlideVideoImg>
                </FadeMobile>
                <FadePC>
                  <SlideVideo>
                    <video
                      preload="none"
                      autoplay
                      muted="true"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        objectFit: "cover",
                      }}
                      currentTime="8"
                      loop="true"
                      class="css-ghlqxi"
                      id={"cs_video_1"}
                    >
                      <source src={bannerVideo1} type="video/mp4" />
                    </video>
                  </SlideVideo>
                  <SlideVideoImg
                    onClick={playPopVideo}
                    onMouseOut={stopVideo}
                    onMouseOver={playVideo}
                    id={"cs_video_1_mask"}
                  >
                    <VideoImg
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        marginLeft: "0",
                        marginTop: "0",
                        objectFit: "cover",
                      }}
                      fluid={data.web021Img.childImageSharp.fluid}
                    />
                    <VideoImg objectFit="contain" fluid={data.videoPlayIconNew.childImageSharp.fluid} />
                  </SlideVideoImg>
                </FadePC>
              </BannerVideoBox>
            </LeftItem>
            <LeftItem>
              <BannerVideoBox>
                <FadeMobile>
                  <SlideVideoImg onClick={playPopVideoMob} id={"cs_video_2_mask_mob"}>
                    <VideoImg
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        marginLeft: "0",
                        marginTop: "0",
                        objectFit: "cover",
                      }}
                      fluid={data.web022Img.childImageSharp.fluid}
                    />
                    <VideoImg objectFit="contain" fluid={data.videoPlayIconNew.childImageSharp.fluid} />
                  </SlideVideoImg>
                </FadeMobile>
                <FadePC>
                  <SlideVideo>
                    <video
                      preload="none"
                      autoplay
                      muted="true"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        objectFit: "cover",
                      }}
                      currentTime="8"
                      loop="true"
                      class="css-ghlqxi"
                      id={"cs_video_2"}
                    >
                      <source src={bannerVideo2} type="video/mp4" />
                    </video>
                  </SlideVideo>
                  <SlideVideoImg
                    onClick={playPopVideo}
                    onMouseOut={stopVideo}
                    onMouseOver={playVideo}
                    id={"cs_video_2_mask"}
                  >
                    <VideoImg
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        marginLeft: "0",
                        marginTop: "0",
                        objectFit: "cover",
                      }}
                      fluid={data.web022Img.childImageSharp.fluid}
                    />
                    <VideoImg objectFit="contain" fluid={data.videoPlayIconNew.childImageSharp.fluid} />
                  </SlideVideoImg>
                </FadePC>
              </BannerVideoBox>
            </LeftItem>
          </LeftBox>
        </FlexWrapper>
      </Container>

      {popVideo ? (
        <VideoPlayBox>
          <VideoClose onClick={closePopVideo}>
            <svg viewBox="0 0 14 14" focusable="false" class="chakra-icon css-1xd2ngu">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="currentColor"
              ></path>
            </svg>
          </VideoClose>
          <VideoContBox>
            <video
              preload="none"
              style={{ width: "100%" }}
              autoplay
              controls="true"
              playsinline="playsinline"
              controlsList="nodownload"
              class="css-ghlqxi"
              id="pop_video"
            >
              <source src={popVideo} type="video/mp4" />
            </video>
          </VideoContBox>
        </VideoPlayBox>
      ) : (
        false
      )}
    </WrapperContainer>
  );
};

export default DownloadAppSection;

const CTA = styled(OutboundLink)`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledSVG = styled.div`
  // background-color: ${({ theme }) => theme.colors.white.regular};
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }

  ${({ theme }) => theme.mq.md`
  width: 100px;
  height: 100px;

  svg {
    width: 100px;
    height: 100px;
  }
  `}

  ${({ theme }) => theme.mq.lg`
  width: 163px;
  height: 154px;

   svg {
    width: 163px;
    height: 154px;
  }
  `}

  ${({ theme }) => theme.mq.xl`
    width: 163px;
  height: 154px;

   svg {
     width: 163px;
     height: 154px;
  }
  `}
`;

const StyledTextWrapper = styled.div`
  word-wrap: break-word;
  padding-bottom: 1.5vw;
  margin-left: 0;

  ${({ theme }) => theme.mq.md`
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-left: 0;
    // width: calc(100% - 675px);
    flex-shrink: 0;
    padding-bottom: 0;
    margin-left: 0;
  `}

  ${({ theme }) => theme.mq.lg`
    //  width: calc(100% - 675px);
     padding-bottom: 0;
     margin-left: 10px;
  `}

  ${({ theme }) => theme.mq.xl`
    // width: calc(100% - 675px);
    padding-bottom: 0;
    margin-left: 0px;
  `}
`;

const FlexWrapper = styled(Flex)`
  flex-direction: column-reverse;
  z-index: 1;
  justify-content: flex-start;
  /* padding: 20rem 0; */
  padding: 4vw 5.65vw 0 5.65vw;
  margin: 0 0 0 0;
  ${({ theme }) => theme.mq.md`
    padding: 4vw 5.65vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
  `}

  ${({ theme }) => theme.mq.lg`
    align-items: center;
    flex-direction: row;
    padding: 0 5.65vw;
    // height: 470px;
    justify-content: flex-start;
    flex-direction: inherit;
  `}
  ${({ theme }) => theme.mq.xl`
    align-items: center;
    flex-direction: row;
    padding: 0 5.65vw;
    // height: 470px;
    justify-content: flex-start;
    flex-direction: inherit;
  `}
`;

const FadeMobile = styled.div`
  display: block;
  ${({ theme }) => theme.mq.sm`
    display: block;
  `}
  ${({ theme }) => theme.mq.md`
    display: block;
  `}
  ${({ theme }) => theme.mq.lg`
    display: none;
  `}
  ${({ theme }) => theme.mq.xl`
    display: none;
  `}
`;
const FadePC = styled.div`
  display: none;
  ${({ theme }) => theme.mq.sm`
    display: none;
  `}
  ${({ theme }) => theme.mq.md`
    display: none;
  `}
  ${({ theme }) => theme.mq.lg`
    display: block;
  `}
  ${({ theme }) => theme.mq.xl`
    display: block;
  `}
`;
const ImageWrapper = styled(Img)`
  width: 570px;
  margin-left: 65px;
  ${({ theme }) => theme.mq.md`
    width: 570px;
    display: flex;
  `}

  ${({ theme }) => theme.mq.lg`
    width: 570px;
    display: flex;
    flex-shrink: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 570px;
    display: flex;
    flex-shrink: 0;

  `}
`;

const HeadingWrapper = styled(Heading)`
  text-align: center;
  margin-bottom: 20px;
  color: #210F60;
  padding: 10px 0;

  ${({ theme }) => theme.mq.md`
    margin-bottom: 10px;
    font-size: 25px;
    text-align: left;
  `}

  ${({ theme }) => theme.mq.lg`
    font-size: 30px;
    //  white-space: pre-line;
     width: 545px;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-bottom: 10px;
    text-align: left;
    width: 545px;
    font-size: 40px;
    line-height: 55px;
  `}
`;

const WrapperContainer = styled.div`
  width: 100%;
  background: #210F60;
  ${({ theme }) => theme.mq.md`
    // height: 470px;
  `}

  ${({ theme }) => theme.mq.lg`
    // height: 470px;
    background: #F8FBFF;
  `}

  ${({ theme }) => theme.mq.xl`
    // height: 470px;
    background: #F8FBFF;
  `}

`;

const LeftBox = styled.div`
  // width: 500px;
  z-index: 1;
  margin-right: 25px;
`;

const LeftItem = styled.div`
  width: 88.67vw;
  height: 29.441vw;
  margin-bottom: 3.33vw;
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  ${({ theme }) => theme.mq.lg`
    width: 43.65vw;
    height: 14.78vw;
    margin-bottom: 1.39vw;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 43.65vw;
    height: 14.78vw;
    margin-bottom: 1.39vw;
  `}
`;

const BannerVideoBox = styled.div`
  width: 100%;
  height: 100%;
`;

const SlideVideo = styled.div`
  width: 100%;
  height: 100%;
`;

const SlideVideoImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transition: opacity 0.5s;
  cursor: pointer;
`;

const VideoImg = styled(Img)`
  width: 90px;
  height: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -45px;
  z-index: 1;
  pointer-events: none;
`;

const BannerImg = styled(Img)`
  width: 100%;
`;

const RightItemTitle = styled.div`
  font-size: 5.56vw;
  font-weight: 500;
  color: #fff;
  line-height: 8.33vw;
  margin-bottom: 3.44vw;
  padding: 1vw 0 0 0;

  ${({ theme }) => theme.mq.lg`

    color: #210f60;
    font-size: 3.13vw;
    font-weight: 600;
    width: 42.3vw;
    line-height: 5.21vw;
    margin-bottom: 1.39vw;
    padding: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    color: #210f60;
    font-size: 3.13vw;
    font-weight: 600;
    width: 42.3vw;
    line-height: 5.21vw;
    margin-bottom: 1.39vw;
    padding: 0;

  `}
`;

const RightItemSubTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  line-height: 36px;
  margin-bottom: 6px;
  padding-left: 12px;
`;

const RightItemDesc = styled.div`
  font-size: 3.33vw;
  font-weight: 400;
  color: #fff;
  line-height: 5.83vw;
  margin-bottom: 4.44vw;

  ${({ theme }) => theme.mq.lg`
    font-size: 1.39vw;
    font-weight: 400;
    color: #210f60;
    line-height: 2.34vw;
    margin-bottom: 1.39vw;
    width: 33.71vw;
  `}

  ${({ theme }) => theme.mq.xl`
    font-size: 1.39vw;
    font-weight: 400;
    color: #210f60;
    line-height: 2.34vw;
    margin-bottom: 1.39vw;
    width: 33.71vw;
  `}
`;

const VideoPlayBox = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #210f60;
  z-index: 999;
`;

const VideoContBox = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 20vh;
  ${({ theme }) => theme.mq.xl`
    width: 60%;
  `}
  ${({ theme }) => theme.mq.lg`
    width: 60%;
  `}
`;

const VideoClose = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 16vh;
  right: 5vw;
  font-size: 20px;
  color: white;
  cursor: pointer;
  ${({ theme }) => theme.mq.xl`
    right: 20vw;
  `}
  ${({ theme }) => theme.mq.lg`
    right: 20vw;
  `}
`;

const LeftTels = styled.div`
  display: flex;
`;
const LeftTelsLeft = styled.div`
  width: 5.56vw;
  height: 5.56vw;
  margin-right: 2.78vw;
  transform: translateY(1.3vw);
  ${({ theme }) => theme.mq.xl`
    width: 0.8vw;
    height: 0.8vw;
    margin-right: 0.84vw;
    transform: translateY(0.3vw);
  `}
  ${({ theme }) => theme.mq.lg`
    width: 0.8vw;
    height: 0.8vw;
    margin-right: 0.84vw;
    transform: translateY(0.3vw);
  `}
`;

const LeftTelsRight = styled.div`
  font-size: 3.05vw;
  line-height: 6.11vw;
  color: #fff;
  margin-left: -5px;
  p {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.lg`
    font-size: 1.04vw;
    line-height: 1.39vw;
    color: #210f60;
    margin-left: -5px;
    p {
      margin-bottom: 0;
    }
  `}

  ${({ theme }) => theme.mq.xl`
    font-size: 1.04vw;
    line-height: 1.39vw;
    color: #210f60;
    margin-left: -5px;
    p {
      margin-bottom: 0;
    }
  `}
`;
