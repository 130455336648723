import styled from "styled-components";
import HeroSection from "../../hero";
import Img from "gatsby-image";
import Image from "gatsby-image";

const Item = styled.div`
  width: 90%;
  position: relative;
  padding-top: 31.8rem;
  flex-shrink: 0;
  max-width: 373px;
  z-index: 10;
  margin: 0 auto;
  ${({ theme }) => theme.mq.sm`
    padding-top: 318px;
    width: 373px;
    height: 719px;
    &:not(:last-child) {
      margin-right: 0;
    }
    margin: 0 auto;
  `}
  ${({ theme }) => theme.mq.md`
    padding-top: 318px;
    width: 373px;
    height: 719px;
    &:not(:last-child) {
      margin-right: 0;
    }
  `}
  ${({ theme }) => theme.mq.lg`
    padding-top: 318px;
    margin-top: 0;
    width: 373px;
    height: 719px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  `}
  ${({ theme }) => theme.mq.xl`
    margin-top: 0;
    width: 373px;
    height: 719px;
    padding-top: 318px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  `}
`;

const InnerBlock = styled.div`
  width: 100%;
  padding: 101px 20px 20px;
  // background-color: ${props => (props.bg === "accent" ? props.theme.colors.accent : "#F8FBFF")};
  border-radius: 16px;
  ${({ theme }) => theme.mq.lg`
     padding: 101px 40px 0;
     height: 400px;
  `}
  ${({ theme }) => theme.mq.xl`
    padding: 101px 40px 0;
    height: 400px;
  `}
`;
const Container = styled.div``;

const Section = styled.section`
  overflow: hidden;
  background-color: ${props => (props.bg ? props.bg : "#fff")};
  width: 311px;
`;

const Star = styled.img`
  width: 30px;
  height: 30px;
`;
const BigImg = styled(Img)`
  position: absolute !important;
  width: 111%;
  // right: -25%;
  right: -28%;
  bottom: 22%;
  ${({ theme }) => theme.mq.sm`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.md`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.lg`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.xl`
    bottom: 22%;
  `}
`;

const CommonImg = styled(Img)`
  width: 100%;
  right: -25%;
  bottom: 24%;
  position: absolute !important;
  z-index: 5;
  ${({ theme }) => theme.mq.md`
    bottom: 24%;
  `}
  ${({ theme }) => theme.mq.lg`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.xl`
    bottom: 22%;
  `}
`;

const Title = styled.div`
  font-size: 6.67vw;
  line-height: 10vw;
  font-weight: 600;
  // color: ${props => (props.color ? props.color : "#fff")};
  margin: 6.67vw 0;
  color: #210F60;


  ${({ theme }) => theme.mq.lg`
    font-size: 3.13vw;
    line-height: 5.21vw;
    margin: 2.6vw 0;
  `}
  ${({ theme }) => theme.mq.xl`
    font-size: 3.13vw;
    line-height: 5.21vw;
    margin: 2.6vw 0;
  `}
`;

const FadeMobile = styled.div`
  display: block;
  ${({ theme }) => theme.mq.sm`
    display: block;
  `}
  ${({ theme }) => theme.mq.md`
    display: block;
  `}
  ${({ theme }) => theme.mq.lg`
    display: none;
  `}
  ${({ theme }) => theme.mq.xl`
    display: none;
  `}
`;
const FadePC = styled.div`
  display: none;
  ${({ theme }) => theme.mq.sm`
    display: none;
  `}
  ${({ theme }) => theme.mq.md`
    display: none;
  `}
  ${({ theme }) => theme.mq.lg`
    display: block;
  `}
  ${({ theme }) => theme.mq.xl`
    display: block;
  `}
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  // color: ${props => (props.color ? props.color : "#fff")};
`;

const Flex = styled.div`
  padding: 0 0 30px;
  background: #F4FCFA;
  ${({ theme }) => theme.mq.md`
    align-items: center;
    padding: 14px 0 30px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding: 14px 0 30px;
    background: #F4FCFA;
  `}

  ${({ theme }) => theme.mq.xl`
    padding: 14px 0 30px;
    background: none;
  `}
`;

const SlideItem = styled.div`
  position: relative;
`;

const SlideVideoBox = styled.div`
  width: 77.78vw;
  height: 77.78vw;
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.mq.lg`
    width: 21.3vw;
    height: 21.3vw;
    border-radius: 12px;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 21.3vw;
    height: 21.3vw;
    border-radius: 12px;
  `}
`;

const SlideVideo = styled.div`
  width: 100%;
  height: 100%
  position: absolute;
  top: 0;
  left: 0;
`;

const SlideVideoImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  transition: opacity 0.5s;
  cursor: pointer;
`;

const SlideCustomerName = styled.div`
  width: 100%;
  height: 100%;
  font-size: 3.88vw;
  bottom: 0;
  display: inline-block;
  color: #210f60;

  ${({ theme }) => theme.mq.lg`
    font-size: 0.6vw;
  `}
  ${({ theme }) => theme.mq.xl`
    font-size: 0.6vw;
  `}
`;

const SlideVideoPlayOverIcon = styled(Image)`
  width: 90px;
  height: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -45px;
  z-index: 2;
`;

const VideoImg = styled(Image)`
  width: 90px;
  height: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -45px;
  z-index: 1;
  pointer-events: none;
`;

const SlideVideoDesc = styled.div`
  width: 100%;
  height: 100%;
  font-size: 4.44vw;
  line-height: 6.67vw;
  margin: 4.44vw 0;
  color: #697988;

  ${({ theme }) => theme.mq.lg`
    font-size: 1.04vw;
    line-height: 1.47vw;
    margin: 1.39vw 0;
  `}
  ${({ theme }) => theme.mq.xl`
    font-size: 1.04vw;
    line-height: 1.47vw;
    margin: 1.39vw 0;
  `}
`;

const VideoPlayBox = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #210f60;
  z-index: 999;
`;

const VideoContBox = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 20vh;
  background: #000;
  min-height: 10vw;
  ${({ theme }) => theme.mq.lg`
    width: 60%;
    margin: 0 auto;
    margin-top: 20vh;
  `}
  ${({ theme }) => theme.mq.xl`
    width: 60%;
    margin: 0 auto;
    margin-top: 20vh;
  `}
`;

const VideoClose = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 16vh;
  right: 5vw;
  font-size: 20px;
  color: white;
  cursor: pointer;
  ${({ theme }) => theme.mq.lg`
    right: 20vw;
  `}
  ${({ theme }) => theme.mq.xl`
    right: 20vw;
  `}
`;

const VideoBox = styled.div`
  width: 245px;
`;

export {
  Container,
  VideoClose,
  SlideVideoImg,
  VideoContBox,
  VideoPlayBox,
  SlideCustomerName,
  SlideVideoPlayOverIcon,
  SlideVideoDesc,
  VideoImg,
  SlideVideo,
  SlideVideoBox,
  SlideItem,
  Item,
  InnerBlock,
  Section,
  Star,
  Title,
  FadeMobile,
  FadePC,
  Content,
  BigImg,
  CommonImg,
  Flex,
};
