import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Fade from "react-reveal/Fade";
import { ContainerCustom } from "../../../global";
import whiteStar from "../../../../images/product/whiteStar.png";
import blueStar from "../../../../images/product/blueStar.png";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import "./style.css";

import {
  Item,
  InnerBlock,
  Star,
  Title,
  Content,
  VideoPlayBox,
  VideoClose,
  VideoContBox,
  SlideVideoBox,
  SlideCustomerName,
  SlideVideoPlayOverIcon,
  SlideVideoDesc,
  VideoImg,
  SlideVideo,
  SlideVideoImg,
  Flex,
  SlideItem,
  FadeMobile,
  FadePC,
  CommonImg,
} from "./style";
const List = () => {
  return (
    <ContainerCustom
      style={{ padding: "0 6.26vw 0 6.26vw", width: "100vw", maxWidth: "100vw" }}
      id="home-list"
      pd="0 50px"
      position="relative"
    >
      <FadeMobile>
        <Title>
          Here is what
          <br /> our customers think
        </Title>
      </FadeMobile>
      <FadePC>
        <Title>Here is what our customers think</Title>
      </FadePC>
      <Flex>
        <SwiperList />
      </Flex>
    </ContainerCustom>
  );
};

const SwiperList = () => {
  const [num, setNum] = useState(null);
  const [popVideo, setPopVideo] = useState(null);
  const [popVideIsMob, setPopVideIsMob] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      transferImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "transfer" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      owealthImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "owealth" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      cardsImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "cards" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      openingImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "opening" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      airtimeImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "airtime2" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      videoPlayIconNew: file(sourceInstanceName: { eq: "product" }, name: { eq: "video-play-icon" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg1: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-0" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg2: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-1" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg3: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-2" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg4: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-3" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg5: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-4" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg6: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-5" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg7: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-6" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg8: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-7" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customerThinkImg9: file(sourceInstanceName: { eq: "product" }, name: { eq: "customer-think-img-8" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const listArr = [
    {
      video: "https://files.opayweb.com/file/video/01MrMalik.mp4",
      videoIframe: 1,
      content:
        "OPay makes banking very easy for me; transfer of funds & transactions are seamless; I give OPay a solid 10/10.",
      name: "Mr Malik (Customer)",
      img: data.customerThinkImg1.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/02MrsBenson.mp4",
      videoIframe: 2,
      content:
        "I Love OPay! the network is very good...up to 100% network uptime and super fast & their customer service is quick, fast and efficient, complaints are resolved qucikly.",
      name: "Mrs Benson (Agent)",
      img: data.customerThinkImg2.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/03MummyHabeeb.mp4",
      videoIframe: 3,
      content:
        "OPay has been giving me joy since 2020! Ah, within one to two seconds, you will surely see the money transferred to you!",
      name: "Mummy Habeeb (Agent)",
      img: data.customerThinkImg3.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/04MrQudusAdebayo.mp4",
      videoIframe: 4,
      content:
        "I feel very satisfied with OPay's service, I've been using OPay for over 4 years and will definitely recommend OPay to my family & firends",
      name: "Mr Adebayo (Customer)",
      img: data.customerThinkImg4.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/05MrOGBONNA.mp4",
      videoIframe: 5,
      content:
        "To be sincere, I'm very happy with OPay, my money has never hanged before.. All my doubts and the rumours heard about OPay being not secure, are cleared now!",
      name: "Mr Ogbonna (Farmer/Businessman)",
      img: data.customerThinkImg5.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/06MrOyebadejo.mp4",
      videoIframe: 6,
      content:
        "I wrongly sent money to an account, but OPay resolved the issue for me in 15 to 30 minutes, I am very happy & comfortable that the issue was addressed so swiftly!",
      name: "Mr Oyebadejo (Customer)",
      img: data.customerThinkImg6.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/07MrOGBONNA.mp4",
      videoIframe: 7,
      content:
        "I am really impressed with OPay's quick customer service and their super fast complaint resolution! I definitely will recommend OPay to others",
      name: "Ms. Robson (Student)",
      img: data.customerThinkImg7.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/08MontageA.mp4",
      videoIframe: 8,
      content: "I Here's how excited our customers are about our service. Check out all the fun clips",
      name: "OPay loyalty interview",
      img: data.customerThinkImg8.childImageSharp.fluid,
    },
    {
      video: "https://files.opayweb.com/file/video/09MontageB.mp4",
      videoIframe: 9,
      content: "Here's what our customers have to say about OPay! Click here to watch",
      name: "OPay loyalty interview",
      img: data.customerThinkImg9.childImageSharp.fluid,
    },
  ];

  const [isMob, setIsMob] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMob(true);
    }
  }, []);

  const playVideo = e => {
    // console.log(e.currentTarget.getAttribute('id').split('_mask')[0])
    let videoId = e.currentTarget.getAttribute("id");
    let videoDom = document.getElementById(videoId.split("_mask")[0]);
    if (videoDom && videoDom.paused) {
      videoDom.play();
      document.getElementById(videoId).style.opacity = 0;
      // e.currentTarget.style.opacity = 0
    }
    e.stopPropagation();
  };

  const stopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoDom = document.getElementById(videoId.split("_mask")[0]);
    if (!videoDom.paused) {
      setTimeout(() => {
        videoDom.pause();
      }, 300);
      document.getElementById(videoId).style.opacity = 1;
    }
    e.stopPropagation();
  };

  const playPopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoIndex = videoId.split("_mask")[0].split("video_")[1];
    setPopVideo(listArr[videoIndex]);
    setPopVideIsMob(false);
    setTimeout(() => {
      document.getElementById("pop_video").play();
    }, 100);
    console.log(videoIndex);
  };

  const playPopVideoMob = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoIndex = videoId.split("_mask")[0].split("video_")[1];
    setPopVideo(listArr[videoIndex]);
    setPopVideIsMob(true);
    console.log(videoIndex);
  };

  const closePopVideo = () => {
    setPopVideo(null);
  };

  return (
    <>
      <Swiper
        className="mySwiper"
        spaceBetween={90}
        slidesPerView={1}
        navigation={isMob ? false : true}
        modules={[Navigation]}
        freeMode={true}
        mousewheel={true}
        forceToAxis={true}
        eventsTarged={"body"}
        breakpoints={{
          300: {
            slidesPerView: 1.1,
            spaceBetween: 16,
          },
          992: {
            //当屏幕宽度大于等于992
            slidesPerView: 3.5,
            spaceBetween: 90,
          },
          1400: {
            //当屏幕宽度小于等于1400
            slidesPerView: 3.5,
            spaceBetween: 90,
          },
        }}
      >
        {listArr.map((item, index) => {
          return (
            //
            <SwiperSlide style={{}} key={index}>
              <SlideItem>
                <SlideVideoBox>
                  <SlideVideo>
                    {/* autoPlay="true" muted="" playsinline="" disablepictureinpicture="" preload="none" loop=""  */}
                    <video
                      preload="none"
                      muted="true"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        objectFit: "cover",
                      }}
                      loop=""
                      class="css-ghlqxi"
                      id={"video_" + index}
                    >
                      <source src={item.video} type="video/mp4" />
                    </video>
                  </SlideVideo>
                  <FadePC>
                    <SlideVideoImg
                      onClick={playPopVideo}
                      onMouseOut={stopVideo}
                      onMouseOver={playVideo}
                      id={"video_" + index + "_mask"}
                    >
                      <VideoImg
                        style={{
                          height: "108%",
                          width: "108%",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          marginLeft: "-2.5%",
                          marginTop: "-2%",
                          objectFit: "cover",
                        }}
                        fluid={item.img}
                      />
                      <VideoImg objectFit="contain" fluid={data.videoPlayIconNew.childImageSharp.fluid} />
                    </SlideVideoImg>
                  </FadePC>
                  <FadeMobile>
                    <SlideVideoImg onClick={playPopVideoMob} id={"video_" + index + "_mask"}>
                      <VideoImg
                        style={{
                          height: "108%",
                          width: "108%",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          marginLeft: "-2.5%",
                          marginTop: "-2%",
                          objectFit: "cover",
                        }}
                        fluid={item.img}
                      />
                      <VideoImg objectFit="contain" fluid={data.videoPlayIconNew.childImageSharp.fluid} />
                    </SlideVideoImg>
                  </FadeMobile>
                </SlideVideoBox>
                <SlideVideoDesc>{item.content}</SlideVideoDesc>
                <SlideCustomerName>{item.name}</SlideCustomerName>
              </SlideItem>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {popVideo ? (
        <VideoPlayBox>
          <VideoClose onClick={closePopVideo}>
            <svg viewBox="0 0 14 14" focusable="false" class="chakra-icon css-1xd2ngu">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="currentColor"
              ></path>
            </svg>
          </VideoClose>
          <VideoContBox>
            <FadeMobile>
              {popVideo["videoIframe"] == 1 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/0qiECGoCuXc"
                  title="What our customers think about OPay!"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 2 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/Icb5_qJsSEc"
                  title="OPay Street Interview - Mrs Benson"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 3 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/gm3rMD9yOHI"
                  title="OPay Loyalty Interview - Mummy Habeeb"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 4 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/KogDfitYeT0"
                  title="OPay Quick customer interview -  Mr Qudus Adebayo"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 5 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/tGmRBbvxWi4"
                  title="OPay quick customer service testimonial"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 6 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/mn6lV_h_CWs"
                  title="OPay Quick customer service testimonial 2"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 7 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/rRa_WqxgoPE"
                  title="OPay Quick customer service testimonial - Ms Robson"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 8 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/Mtid5VyJKiE"
                  title="OPay Street Talks"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
              {popVideo["videoIframe"] == 9 ? (
                <iframe
                  width="100%"
                  height="210vw"
                  src="https://www.youtube.com/embed/amR8r5M6vY4?list=UULFw1lcCTwwpR0RTf7STQpYDw"
                  title="OPay customers&#39; voices"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                false
              )}
            </FadeMobile>
            <FadePC>
              {popVideIsMob ? (
                false
              ) : (
                <video
                  preload="none"
                  style={{ width: "100%" }}
                  autoplay
                  controls="true"
                  controlslist="nodownload"
                  class="css-ghlqxi"
                  id="pop_video"
                >
                  <source src={popVideo["video"]} type="video/mp4" />
                </video>
              )}
            </FadePC>
          </VideoContBox>
        </VideoPlayBox>
      ) : (
        false
      )}
    </>
  );
};

export default List;
