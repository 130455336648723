import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation-new";

import Header from "../components/sections/home-new/header";
// import Footer from "../components/sections/footer";
// import SecurityAndPrivacy from "../components/sections/home/security-and-privacy";

import ArrowIndicator from "../components/sections/home-new/arrow";
// import DoMore from "../components/sections/home/doMore";
// import Inquiries from "../components/sections/home/inquiries";
// import BetterLife from "../components/sections/better-life";
// import OurPartners from "../components/sections/home/our-partners";
// import OpayCard from "../components/sections/home/opay-card";
import List from "../components/sections/home-new/list";
import OurServices from "../components/sections/home-new/our-services";
import CustomersThink from "../components/sections/home-new/customers-think";
import BottomBigVideo from "../components/sections/home-new/bottom-big-video";
import SecurityProduct from "../components/sections/home-new/security-product";
import CustomerSatisfation from "../components/sections/home-new/customer-satisfation";
import OfUsers from "../components/sections/home-new/of-users";
import Cards from "../components/sections/home-new/debit-cards";
import DownloadAppSection from "../components/sections/home-new/download-app";

import Security from "../components/sections/home-new/security";
import Saying from "../components/sections/home-new/saying";
import Help from "../components/sections/home-new/help";
// import NewFooter from "../components/sections/home/new-footer";
import Footer from "../components/sections/footer/index-new";
import { useEffect } from "react";
import dataBuried from "../utils/dataBuried";

const IndexPage = () => {
  useEffect(() => {
    dataBuried("opay_website_home_show");
  }, []);

  return (
    <Layout>
      <SEO title="OPay" />
      <Navigation background="white" />
      <Header />
      <List />
      <OurServices />
      {/* <OfUsers /> */}
      {/* <Cards /> */}
      <DownloadAppSection />
      <CustomersThink />
      <SecurityProduct />
      <CustomerSatisfation />
      <BottomBigVideo />
      {/* <Saying />
      <Security />
      <Help /> */}
      <Footer />
    </Layout>
  );
};

export default IndexPage;
