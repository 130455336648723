import styled from "styled-components";
import HeroSection from "../../hero";
import Img from "gatsby-image";

const Item = styled.div`
  position: relative;
  padding-top: 0;
  flex-shrink: 0;
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.mq.sm`
    // padding-top: 13.1vw;
    // height: 17vw;
    // &:not(:last-child) {
    //   margin-right: 0;
    // }
    // margin: 0 auto;
  `}
  ${({ theme }) => theme.mq.md`
    // padding-top: 13.1vw;
    // height: 17vw;
    // &:not(:last-child) {
    //   margin-right: 0;
    // }
  `}
  ${({ theme }) => theme.mq.lg`
  padding-top: 15.1vw;
  `}
  ${({ theme }) => theme.mq.xl`
  padding-top: 15.1vw;
  `}
`;

const InnerBlock = styled.div`
  width: 100%;
  height: auto;
  padding: 0 11px 0 16px;
  background: none;
  // background-color: ${props => (props.bg === "accent" ? props.theme.colors.accent : "#F8FBFF")};
  border-radius: 16px;
  ${({ theme }) => theme.mq.lg`
     padding: 28px 11px 0 16px;
     height: 15.3vw;
     background: #F8FBFF;
  `}
  ${({ theme }) => theme.mq.xl`
    padding: 28px 11px 0 16px;
    height: 15.3vw;
    background: #F8FBFF;
  `}
`;
const Container = styled.div``;

const Section = styled.section`
  overflow: hidden;
  background-color: ${props => (props.bg ? props.bg : "#fff")};
  width: 311px;
`;

const Star = styled.img`
  width: 30px;
  height: 30px;
`;
const Icon = styled.div`
  width: 30px;
  height: 30px;
  font-size: 24px;
`;

const BigImg = styled(Img)`
  position: absolute !important;
  width: 111%;
  // right: -25%;
  right: -28%;
  bottom: 22%;
  ${({ theme }) => theme.mq.sm`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.md`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.lg`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.xl`
    bottom: 22%;
  `}
`;

const CommonImg = styled(Img)`
  width: 33.33vw;
  right: 0;
  bottom: 0;
  position: relative;
  z-index: 5;
  ${({ theme }) => theme.mq.md`
    bottom: 12vw;
  `}
  ${({ theme }) => theme.mq.lg`
    bottom: 12vw;
    width: 9.2vw;
    position: absolute !important;
  `}
  ${({ theme }) => theme.mq.xl`
    bottom: 12vw;
    width: 9.2vw;
    position: absolute !important;
  `}
`;

const Title = styled.div`
  font-size: 2.78vw;
  line-height: 4.44vw;
  font-weight: 700;
  // color: ${props => (props.color ? props.color : "#fff")};
  color: #210F60 !important;
  margin: 2.17vw 0 0.34vw;

  ${({ theme }) => theme.mq.lg`

    font-size: 0.95vw;
    line-height: 1.04vw;
    font-weight: 700;
    // color: ${props => (props.color ? props.color : "#fff")};
    color: #210F60 !important;
    margin: 4.17vw 0 0.34vw;
  `}
  ${({ theme }) => theme.mq.xl`

    font-size: 0.95vw;
    line-height: 1.04vw;
    font-weight: 700;
    // color: ${props => (props.color ? props.color : "#fff")};
    color: #210F60 !important;
    margin: 4.17vw 0 0.34vw;
  `}
`;

const ContentNew = styled.p`
  font-size: 2.78vw;
  line-height: 4.44vw;
  font-weight: 400;
  color: #1DC99B !important;
  // color: ${props => (props.color ? props.color : "#fff")};


  ${({ theme }) => theme.mq.lg`
    font-size: 0.65vw;
    line-height: 1.13vw;
    font-weight: 400;
    color: #1DC99B !important;
  `}
  ${({ theme }) => theme.mq.xl`
    font-size: 0.65vw;
    line-height: 1.13vw;
    font-weight: 400;
    color: #1DC99B !important;
  `}
`;

const Flex = styled.div`
  padding: 0 0 30px;
  ${({ theme }) => theme.mq.md`
    align-items: center;
    padding: 30px 0 40px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding: 30px 0 40px;
  `}

  ${({ theme }) => theme.mq.xl`
    padding: 30px 0 40px;
  `}
`;

const FadeMobile = styled.div`
  display: block;
  ${({ theme }) => theme.mq.sm`
    display: block;
  `}
  ${({ theme }) => theme.mq.md`
    display: block;
  `}
  ${({ theme }) => theme.mq.lg`
    display: none;
  `}
  ${({ theme }) => theme.mq.xl`
    display: none;
  `}
`;
const FadePC = styled.div`
  display: none;
  ${({ theme }) => theme.mq.sm`
    display: none;
  `}
  ${({ theme }) => theme.mq.md`
    display: none;
  `}
  ${({ theme }) => theme.mq.lg`
    display: block;
  `}
  ${({ theme }) => theme.mq.xl`
    display: block;
  `}
`;

export {
  FadeMobile,
  FadePC,
  Container,
  Item,
  InnerBlock,
  Section,
  Icon,
  Star,
  Title,
  ContentNew,
  BigImg,
  CommonImg,
  Flex,
};
