import React, { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Box, Flex, Text, Heading } from "rebass/styled-components";
import { Container } from "../../../global";

import QRCode from "../../../../images/product/new-qr-code.svg";
import { useLocationCountry } from "../../../../hooks";
import Img from "gatsby-image";
import HeroSection from "../../hero";

const DownloadAppSection = () => {
  const [popVideo, setPopVideo] = useState(null);
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          playstoreUrl
          applestoreUrl
        }
      }

      securityBannerNew1: file(sourceInstanceName: { eq: "product" }, name: { eq: "security-banner-1" }) {
        childImageSharp {
          fluid(maxWidth: 741) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      securityBanner2: file(sourceInstanceName: { eq: "product" }, name: { eq: "security-banner-2" }) {
        childImageSharp {
          fluid(maxWidth: 741) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      securityImg131: file(sourceInstanceName: { eq: "product" }, name: { eq: "security-img-131" }) {
        childImageSharp {
          fluid(maxWidth: 741) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      securityImg132: file(sourceInstanceName: { eq: "product" }, name: { eq: "security-img-132" }) {
        childImageSharp {
          fluid(maxWidth: 741) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      videoPlayIcon: file(sourceInstanceName: { eq: "product" }, name: { eq: "video-play-icon" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { isPakistan } = useLocationCountry();
  const bannerVideo1 = "https://files.opayweb.com/file/video/131.mp4";
  const bannerVideo2 = "https://files.opayweb.com/file/video/132.mp4";

  const playVideo = e => {
    // console.log(e.currentTarget.getAttribute('id').split('_mask')[0])
    let videoId = e.currentTarget.getAttribute("id");
    let videoDom = document.getElementById(videoId.split("_mask")[0]);

    if (videoDom && videoDom.paused) {
      videoDom.play();
      document.getElementById(videoId + "_videomask").style.opacity = 0;
      // e.currentTarget.style.opacity = 0
    }
    e.stopPropagation();
  };

  const stopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoDom = document.getElementById(videoId.split("_mask")[0]);
    if (!videoDom.paused) {
      setTimeout(() => {
        videoDom.pause();
      }, 300);
      document.getElementById(videoId + "_videomask").style.opacity = 1;
    }
    e.stopPropagation();
  };

  const playPopVideo = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoIndex = videoId.split("_mask")[0].split("video_")[1];
    let videoUrl = "";
    if (videoIndex == 1) {
      videoUrl = bannerVideo1;
    } else if (videoIndex == 2) {
      videoUrl = bannerVideo2;
    }
    setPopVideo(videoUrl);
    setTimeout(() => {
      document.getElementById("pop_video").play();
    }, 100);
    console.log(videoIndex);
  };

  const playPopVideoMob = e => {
    let videoId = e.currentTarget.getAttribute("id");
    let videoIndex = videoId.split("_mask")[0].split("video_")[1];
    let videoUrl = "";
    if (videoIndex == 1) {
      videoUrl = bannerVideo1;
    } else if (videoIndex == 2) {
      videoUrl = bannerVideo2;
    }
    setPopVideo(videoUrl);
    console.log(videoIndex);
  };

  const closePopVideo = () => {
    setPopVideo(null);
  };

  return (
    <WrapperContainer>
      <Container style={{ width: "100vw", maxWidth: "100vw", padding: "0" }}>
        <FlexWrapper>
          <LeftBox>
            <FadeMobile>
              <LeftItem onClick={playPopVideoMob} id={"secu_video_1_mask"} style={{ cursor: "pointer" }}>
                <BannerImg objectFit="contain" fluid={data.securityBannerNew1.childImageSharp.fluid} />
              </LeftItem>
              <LeftItem onClick={playPopVideoMob} id={"secu_video_2_mask"} style={{ cursor: "pointer" }}>
                <BannerImg objectFit="contain" fluid={data.securityBanner2.childImageSharp.fluid} />
              </LeftItem>
            </FadeMobile>
            <FadePC>
              <LeftItem
                onClick={playPopVideo}
                onMouseOut={stopVideo}
                onMouseOver={playVideo}
                id={"secu_video_1_mask"}
                style={{ cursor: "pointer" }}
              >
                <BannerImg objectFit="contain" fluid={data.securityBannerNew1.childImageSharp.fluid} />
                <FadePC>
                  <BannerVideoBox style={{ pointerEvents: "none" }}>
                    <SlideVideo>
                      <video
                        preload="none"
                        autoplay
                        muted="true"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          objectFit: "cover",
                          pointerEvents: "none",
                        }}
                        loop=""
                        class="css-ghlqxi"
                        id={"secu_video_1"}
                      >
                        <source src={bannerVideo1} type="video/mp4" />
                      </video>
                    </SlideVideo>
                    <SlideVideoImg id="secu_video_1_mask_videomask">
                      <video
                        preload="none"
                        muted="true"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          objectFit: "cover",
                          pointerEvents: "none",
                        }}
                        loop=""
                        class="css-ghlqxi"
                      >
                        <source src={bannerVideo1} type="video/mp4" />
                      </video>
                      <VideoImg
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          marginLeft: "0",
                          marginTop: "0",
                          objectFit: "cover",
                          pointerEvents: "none",
                        }}
                        fluid={data.securityImg131.childImageSharp.fluid}
                      />
                    </SlideVideoImg>
                  </BannerVideoBox>
                </FadePC>
              </LeftItem>
              <LeftItem
                onClick={playPopVideo}
                onMouseOut={stopVideo}
                onMouseOver={playVideo}
                id={"secu_video_2_mask"}
                style={{ cursor: "pointer" }}
              >
                <BannerImg objectFit="contain" fluid={data.securityBanner2.childImageSharp.fluid} />

                <FadePC>
                  <BannerVideoBox style={{ pointerEvents: "none" }}>
                    <SlideVideo>
                      <video
                        preload="none"
                        autoplay
                        muted="true"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          objectFit: "cover",
                        }}
                        loop=""
                        class="css-ghlqxi"
                        id={"secu_video_2"}
                      >
                        <source src={bannerVideo2} type="video/mp4" />
                      </video>
                    </SlideVideo>
                    <SlideVideoImg id="secu_video_2_mask_videomask">
                      <VideoImg
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          marginLeft: "0",
                          marginTop: "0",
                          objectFit: "cover",
                        }}
                        fluid={data.securityImg132.childImageSharp.fluid}
                      />
                    </SlideVideoImg>
                  </BannerVideoBox>
                </FadePC>
              </LeftItem>
            </FadePC>
          </LeftBox>
          <StyledTextWrapper>
            <RightItemTitle>Your Security and Privacy is our priority</RightItemTitle>
            <RightItemSubTitle>Advanced Fraud Detection</RightItemSubTitle>
            <RightItemDesc>
              Your data is stored and encrypted using strong cryptography. We scan our systems daily against security
              threats.
            </RightItemDesc>

            <RightItemSubTitle>Security Product Safe Transactions</RightItemSubTitle>
            <RightItemDesc>
              We are compliant with PCI DSS. Your transactions are always encrypted and secured.
            </RightItemDesc>

            <RightItemSubTitle>Security Product</RightItemSubTitle>
            <RightItemDesc>
              Quick dial *955*131# or *955*132# to lock account or card if stolen or misplaced
            </RightItemDesc>
          </StyledTextWrapper>
        </FlexWrapper>
      </Container>

      {popVideo ? (
        <VideoPlayBox>
          <VideoClose onClick={closePopVideo}>
            <svg viewBox="0 0 14 14" focusable="false" class="chakra-icon css-1xd2ngu">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="currentColor"
              ></path>
            </svg>
          </VideoClose>
          <VideoContBox>
            <video
              preload="none"
              style={{ width: "100%" }}
              autoplay
              controls="true"
              controlsList="nodownload"
              class="css-ghlqxi"
              id="pop_video"
              playsinline="playsinline"
            >
              <source src={popVideo} type="video/mp4" />
            </video>
          </VideoContBox>
        </VideoPlayBox>
      ) : (
        false
      )}
    </WrapperContainer>
  );
};

export default DownloadAppSection;

const CTA = styled(OutboundLink)`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledSVG = styled.div`
  // background-color: ${({ theme }) => theme.colors.white.regular};
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }

  ${({ theme }) => theme.mq.md`
  width: 100px;
  height: 100px;

  svg {
    width: 100px;
    height: 100px;
  }
  `}

  ${({ theme }) => theme.mq.lg`
  width: 163px;
  height: 154px;

   svg {
    width: 163px;
    height: 154px;
  }
  `}

  ${({ theme }) => theme.mq.xl`
    width: 163px;
  height: 154px;

   svg {
     width: 163px;
     height: 154px;
  }
  `}
`;

const StyledTextWrapper = styled.div`
  word-wrap: break-word;
  padding-bottom: 2.16vw;
  margin-left: 0;
  ${({ theme }) => theme.mq.md`
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-left: 0;
    // width: calc(100% - 675px);
    flex-shrink: 0;
    padding-bottom: 0;
  `}

  ${({ theme }) => theme.mq.lg`
    //  width: calc(100% - 675px);
     padding-bottom: 0;
     margin-left: 48px;
  `}

  ${({ theme }) => theme.mq.xl`
    // width: calc(100% - 675px);
    padding-bottom: 0;
    margin-left: 48px;
  `}
`;

const FlexWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 5.65vw;
  /* padding: 20rem 0; */
  margin: 0 0 0 0;
  ${({ theme }) => theme.mq.md`
    padding: 0;
    flex-direction: column;
    padding-left:5.65vw;
    justify-content: flex-start;
    align-items:center;
  `}

  ${({ theme }) => theme.mq.lg`
    align-items: center;
    flex-direction: row;
    padding-left:5.65vw;
    justify-content: flex-start;
  `}
`;

const ImageWrapper = styled(Img)`
  width: 570px;
  margin-left: 65px;
  ${({ theme }) => theme.mq.md`
    width: 570px;
    display: flex;
  `}

  ${({ theme }) => theme.mq.lg`
    width: 570px;
    display: flex;
    flex-shrink: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 570px;
    display: flex;
    flex-shrink: 0;

  `}
`;

const HeadingWrapper = styled(Heading)`
  text-align: center;
  margin-bottom: 20px;
  color: #210F60;
  padding: 10px 0;

  ${({ theme }) => theme.mq.md`
    margin-bottom: 10px;
    font-size: 25px;
    text-align: left;
  `}

  ${({ theme }) => theme.mq.lg`
    font-size: 30px;
    //  white-space: pre-line;
     width: 545px;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-bottom: 10px;
    text-align: left;
    width: 545px;
    font-size: 40px;
    line-height: 55px;
  `}
`;

const WrapperContainer = styled.div`
  width: 100%;
  background: #1DC99B;
  padding: 6.67vw 0 0 0;
  ${({ theme }) => theme.mq.md`
    // height: 550px;
  `}

  ${({ theme }) => theme.mq.lg`
    padding: 5vw 0;
    // height: 550px;
  `}

  ${({ theme }) => theme.mq.xl`

    padding: 5vw 0;
    // height: 550px;
  `}

`;

const LeftBox = styled.div`
  // width: 500px;
  z-index: 1;
`;

const LeftItem = styled.div`
  width: 88.88vw;
  margin-bottom: 3vw;
  position: relative;

  ${({ theme }) => theme.mq.lg`
    width: 41.1vw;
    margin-bottom: 1.4vw;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 41.1vw;
    margin-bottom: 1.4vw;
  `}
`;

const FadeMobile = styled.div`
  display: block;
  ${({ theme }) => theme.mq.sm`
    display: block;
  `}
  ${({ theme }) => theme.mq.md`
    display: block;
  `}
  ${({ theme }) => theme.mq.lg`
    display: none;
  `}
  ${({ theme }) => theme.mq.xl`
    display: none;
  `}
`;
const FadePC = styled.div`
  display: none;
  ${({ theme }) => theme.mq.sm`
    display: none;
  `}
  ${({ theme }) => theme.mq.md`
    display: none;
  `}
  ${({ theme }) => theme.mq.lg`
    display: block;
  `}
  ${({ theme }) => theme.mq.xl`
    display: block;
  `}
`;

const BannerVideoBox = styled.div`
  position: absolute;
  width: 28.04vw;
  height: 26.56vw;
  right: 5.58vw;
  top: 5.68vw;
  border-radius: 8px;
  overflow: hidden;
  background: #210f60;

  ${({ theme }) => theme.mq.lg`
    width: 13.04vw;
    height: 12.56vw;
    right: 2.68vw;
    top: 2.68vw;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 13.04vw;
    height: 12.56vw;
    right: 2.68vw;
    top: 2.68vw;
  `}
`;

const SlideVideo = styled.div`
  width: 102%;
  height: 102%;
  margin-top: -1%;
  margin-left: -3%;
`;

const SlideVideoImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transition: opacity 0.5s;
  cursor: pointer;
`;

const VideoImg = styled(Img)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  z-index: 1;
  pointer-events: none;
`;

const BannerImg = styled(Img)`
  width: 100%;
`;

const RightItemTitle = styled.div`
  font-size: 5.55vw;
  width: 80.83vw;
  font-weight: 600;
  color: #fff;
  line-height: 8.33vw;
  margin-bottom: 4.44vw;
  padding-left: 0;
  padding-top: 2vw;

  ${({ theme }) => theme.mq.lg`
    font-size: 3.13vw;
    width: 40.4vw;
    font-weight: 700;
    color: #210f60;
    line-height: 4.65vw;
    margin-bottom: 1vw;
    padding-left: 3vw;
    padding-top: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    font-size: 3.13vw;
    width: 40.4vw;
    font-weight: 700;
    color: #210f60;
    line-height: 4.65vw;
    margin-bottom: 1vw;
    padding-left: 3vw;
    padding-top: 0;
  `}
`;

const RightItemSubTitle = styled.div`
  font-size: 4.44vw;
  font-weight: 500;
  color: #210f60;
  line-height: 6.67vw;
  margin-bottom: 1.11vw;
  padding-left: 0;

  ${({ theme }) => theme.mq.lg`
    font-size: 2.08vw;
    font-weight: 500;
    color: #fff;
    line-height: 3.13vw;
    margin-bottom: 0.34vw;
    padding-left: 3vw;
  `}

  ${({ theme }) => theme.mq.xl`
    font-size: 2.08vw;
    font-weight: 500;
    color: #fff;
    line-height: 3.13vw;
    margin-bottom: 0.34vw;
    padding-left: 3vw;
  `}
`;

const RightItemDesc = styled.div`
  font-weight: 400;
  color: #fff;
  font-size: 3.33vw;
  line-height: 5.83vw;
  margin-bottom: 4.44vw;
  padding-left: 0;
  width: 85.55vw;

  ${({ theme }) => theme.mq.lg`
    
    font-size: 1.04vw;
    line-height: 2.08vw;
    margin-bottom: 1vw;
    padding-left: 3vw;
    width: 36vw;
  `}

  ${({ theme }) => theme.mq.xl`
    
    font-size: 1.04vw;
    line-height: 2.08vw;
    margin-bottom: 1vw;
    padding-left: 3vw;
    width: 36vw;
  `}
`;

const VideoPlayBox = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #210f60;
  z-index: 999;
`;

const VideoContBox = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 20vh;

  ${({ theme }) => theme.mq.lg`
  width: 60%;
  `}

  ${({ theme }) => theme.mq.xl`
  width: 60%;
  `}
`;

const VideoClose = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 16vh;
  right: 5vw;
  font-size: 20px;
  color: white;
  cursor: pointer;

  ${({ theme }) => theme.mq.lg`
  width: 14px;
  right: 20vw;
  `}

  ${({ theme }) => theme.mq.xl`
  width: 14px;
  right: 20vw;
  `}
`;
